import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import { OrderContext } from '../../context/OrderContext';
import MenuItem from './MenuItem';
import Action from '../Action';
import IconSvg from '../../../../common/javascript/components/IconSvg/IconSvg';

import PersonsIcon from '../../../../../icons/persons.svg';
import CheckIcon from '../../../../../icons/radio-on-color.svg';
import PlusIcon from '../../../../../icons/plus.svg';
import DocumentsIcon from '../../../../../icons/documents.svg';
import ChatIcon from '../../../../../icons/chat2.svg';

import Popup from '../Popup';
import usePopup from '../../../../hooks/use-popup';
import { viewedHint } from '../../../../services/order';
import Hint from '../Hint/Hint';

import * as styles from './MenuItems.module.scss';

export const ORDER_ITEMS = {
  orderItems: 'orders',
  subOrders: 'subOrders',
  documents: 'documents',
  members: 'members',
  chat: 'chat'
};

const MenuItems = ({ vertical }) => {
  const { order, itemMenu, handleMenuItemChange, subOrders, allSubOrders } = useContext(OrderContext);
  const [isHintOpened, openHint, closeHint] = usePopup();
  const [content, setContent] = useState(null);

  const scrollToTop = () => {
    document.getElementById('orderSection').scrollIntoView(true);
  };

  const popupHintContent = (item) => {
    if (order.hints?.orders_hint && item === ORDER_ITEMS.orderItems) return order.hints?.orders_hint;
    if (order.hints?.sub_orders_hint && item === ORDER_ITEMS.subOrders) return order.hints?.sub_orders_hint;
    if (order.hints?.documents_hint && item === ORDER_ITEMS.documents) return order.hints?.documents_hint;
    if (order.hints?.members_hint && item === ORDER_ITEMS.members) return order.hints?.members_hint;

    return null;
  };

  const changeAction = (item, viewedHints) => {
    scrollToTop();
    if (viewedHints.includes(item)) return;

    const hint = popupHintContent(item);

    setContent(hint);

    if (!hint) return;

    openHint();
  };

  const items = (
    <>
      <MenuItem isActive={itemMenu === ORDER_ITEMS.orderItems}>
        <Action
          themes={['ButtonMedium']}
          onClick={() => handleMenuItemChange(ORDER_ITEMS.orderItems, changeAction)}
        >
          <div className={styles.MenuItem__wrapLink}>
            <IconSvg icon={CheckIcon} />
            Выбрано
          </div>
        </Action>
      </MenuItem>

      {allSubOrders?.length > 0 && (
        <MenuItem isActive={itemMenu === ORDER_ITEMS.subOrders}>
          <Action
            themes={['ButtonMedium']}
            onClick={() => handleMenuItemChange(ORDER_ITEMS.subOrders, changeAction)}
          >
            <div className={styles.MenuItem__wrapLink}>
              <IconSvg icon={PlusIcon} />
              Добавить
            </div>
          </Action>
        </MenuItem>
      )}

      <MenuItem isActive={itemMenu === ORDER_ITEMS.documents}>
        <Action
          themes={['ButtonMedium']}
          onClick={() => handleMenuItemChange(ORDER_ITEMS.documents, changeAction)}
        >
          <div className={styles.MenuItem__wrapLink}>
            <IconSvg icon={DocumentsIcon} />
            Документы
          </div>
        </Action>
      </MenuItem>

      <MenuItem isActive={itemMenu === ORDER_ITEMS.members}>
        <Action
          themes={['ButtonMedium']}
          onClick={() => handleMenuItemChange(ORDER_ITEMS.members, changeAction)}
        >
          <div className={styles.MenuItem__wrapLink}>
            <IconSvg icon={PersonsIcon} />
            Участники
          </div>
        </Action>
      </MenuItem>

      <MenuItem isActive={itemMenu === ORDER_ITEMS.chat}>
        <Action
          themes={['ButtonMedium']}
          // onClick={() => handleMenuItemChange(ORDER_ITEMS.chat)}
          href={`/${order.pnr}/chat`}
          target='_blank'
        >
          <div className={styles.MenuItem__wrapLink}>
            <IconSvg icon={ChatIcon} />
            Чат
          </div>
        </Action>
      </MenuItem>
    </>
  );

  const handleCloseHint = () => {
    closeHint();
    viewedHint(order.id, itemMenu, (result) => {
      console.log(result);
    });
  };

  return (
    <>
      <ul className={cn(styles.MenuItems, {
        [styles.MenuItems__vertical]: vertical,
        [styles.MenuItems__horizontal]: !vertical
      })}
      >
        {items}
      </ul>

      <Popup
        show={isHintOpened}
        onClose={handleCloseHint}
        variant='small'
      >
        <Hint content={content} onClose={handleCloseHint} />
      </Popup>
    </>
  );
};

MenuItems.propTypes = {
  vertical: PropTypes.bool
};

MenuItems.defaultProps = {
  vertical: false
};

export default MenuItems;
